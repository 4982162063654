import LocalizedStrings from "react-localization";

const locales = {
  en: {
    //Local
    //urlApi: "http://localhost:8083/",

    //Servidor
    urlApi: "https://dev.producao.saveway.com.br/raizen_homolog/",

    //Verificar se o endereço utiliza autenticação por AD || 1 = com AD / 0 = sem AD
    usaAD: 1,

    nomeSistema: "Savetyre - Gestão de Pneus",
    versaoSistema: "3.2.0",

    dataAtualizacao: "17/05/2022",
    atualizar: "Atualizar",
    aplicar: "Aplicar",
    carregando: "Carregando...",
    processando: "Processando...",
    cadastro_novo: "Cadastro Novo",
    sim: "Sim",
    nao: "Não",
    dica: "Regra",
    confirmar: "Confirmar",
    fechar: "Fechar",
    cancelar: "Cancelar",
    exclusao: "Exclusão",
    excluir: "Excluir",
    salvar: "Salvar",
    finalizar: "Finalizar",
    limpar: "Limpar",
    voltar: "Voltar",
    editar: "Editar",
    acoes: "Ações",
    msgErro: "Ocorreu um erro!",
    msgErroAPI: "Erro! Verifique endereço do banco!",
    selecione: "Selecione",
    msgCampoObrigatorio: "Campo Obrigatório",
    msgCamposInvalidos: "Preencha corretamente o formulário, existem campos inválidos",
    password_old: "Senha antiga incorreta.",
    denied_auth: "Acesso Negado.",
    erro_conection: "Verifique a conexão",
    data: "Data",
    hora: "Hora",
    login: "Login",
    nome: "Nome",
    nome_completo: "Nome Completo",
    relatorio: "Relatório",
    comentario: "Comentário",
    adicionar: "Adicionar",
    anexar: "Anexar",
    msgSucesso: "Salvo com sucesso!",
    upload: "Upload",
    global_search: "Pesquisa Global",
    total_registros: "Total de registros",
    initDate: "De",
    endDate: "Até",
    sobre: "Sobre",
    apagar: "Apagar",
    enviar: "Enviar",
    receber: "Receber",
    observacao: "Observação",
    
    inativar: "Inativar frota",

    corrigir_km: "Corrigir km registro",
    corrigir_km_veiculo: "Corrigir km do veículo",
    // Login
    login_welcome: "Bem vindo, preencha o formulário.",
    login_welcome_alterar: "Troca de senha, favor atualizar senha.",
    login_nomeUsuario: "Nome do Usuário",
    login_email: "Usuário",
    login_senha: "Senha",
    login_logar: "Entrar",
    erro_logra: "Usuário ou Senha inválidos",
    Alterar_old: "Senha Antiga",
    Alterar_new: "Nova Senha",
    Alterar_reapet: "Confirmar Senha",
    Alterar_senha_title: "Alterar Senha.",
    login_rec_naoCorreta: "Senha incorreta.",
    login_rec_confirma: "As senhas não conferem.",
    login_rec_alterada: "Senha Alterada.",
    redefinit_senha: "Redefinir Senha",

    //Menu Superior
    menu_alterar_senha: "Alterar senha",

    //Dash
    dash_fase_vida:
      "<em>{`And here's`}</em> <b>{`some`}</b> <u>{`amazing content`}</u>.{`It's very engaging. Right?`}",

    dash_pneus_monitorados: "Pneus Monitorados",
    dash_pneus_estoque: "Em Estoque",
    dash_pneus_em_reforma: "Em Reforma/Conserto",
    dash_pneus_em_Analise: "Em Análise",
    dash_pneus_borracharia: "Em Borracharia",
    dash_pneus_uso: "Em Uso",
    dash_veiculos_monitorados: "Veículos Monitorados",
    dash_veiculos_pendencias: "Pendências",
    dash_veiculos_verificados: "% Veículos com Verificação Completa nos últimos ",
    dash_veiculos_verificados_parcial: "% Veículos com Verificação Parcial nos últimos ",
    dash_dias: " dias ",
    dash_pneus_nao_verificaos: "% de Pneus em Uso Não Verificados nos últimos 30 dias",
    dash_pneus_calib_desc: "% de Pneus com Calibração Desconhecida",
    dash_pneus_classificacao: "Classificação da Calibração dos pneus em uso",

    dash_pneus_fase: "Distribuição de Pneus por Fase de Vida",
    dash_pneus_marca: "Distribuição de Pneus por Marca",
    dash_pneus_descartados: "Pneus Descartados nos Últimos 12 Meses",
    dash_pneus_registrados: "Pneus Registrados nos Últimos 12 Meses",

    //Filtros Consulta de veiculos
    consulta_veiculo_placa: "Placa",
    consulta_veiculo_uso: "Frotas Inativas",
    consulta_veiculo_composicao: "Composição",
    consulta_veiculo_tipo_uso: "Uso",
    consulta_veiculo_frotas_inativas: "Frotas inativas",

    //Relatório
    pneus_nao_verificados: "Relatório de Pneus Não Verificados",
    pneus_nao_verificados_30_dias: "Relatório de Pneus Em Uso Não Verificados há 30 dias",
    pneus_calib_desconhecida: "Relatório de Pneus Em Uso com Calibração Desconhecida",
    pneus_calib_inadequada: "Relatório de Pneus Em Uso com Calibração Inadequada",
    relatorio_veiculos_nao_verificados: "Relatório de Veículos Não Verificados",
    relatorio_veiculos_verificados_parcialmente: "Relatório de Veículos Verificados Parcialmente",
    relatorio_registro_pneu: "Relatório de registro dos pneus",

    posicao: "Posição",
    ultima_operacao: "Ult. Operação",
    data_ult_opr: "Data Ult. Operação",
    status: "Status",
    groove_s1: "S1",
    groove_s2: "S2",
    groove_s3: "S3",
    groove_s4: "S4",
    km: "Km",
    km_anterior: "Km anterior",
    km_remaining: "Km Restante",
    pendencias: "Pendências de Veículos",

    //Relatório Consulta Veiculos
    consulta_veiculo: "Consulta de Veículos",
    //Composição Veiculos
    veiculo_composicao: "Composição do Veículo",

    //Veiculos Update
    update_veiculo: "Editar Veículo",

    //Historico Veiculos
    veiculo_id: "Veículo ID",
    composicao: "Composição",
    board: "Placa",
    n_pneus: "N° de Pneus",
    n_estepes: "N° de Estepes",
    history_veiculo: "Histórico do Veículo",
    vehicle_operation_history: "Histórico da Operação do Veículo",
    vehicle_operation: "Operação Veículo",
    date_operation: "Data Operação",
    operator: "Operador",

    //Consulta Pneus
    //Tire Consultation
    tire_consult: "Consulta de Pneus",
    consult: "Consultar",

    //Filtros Consulta de Pneus
    //Tire Inquiry Filters
    tire_id: "Pneu ID",
    brand_fire: "Marca Fogo",
    fleet_id: "Frota ID",
    brand: "Marca",
    model: "Modelo",
    measure: "Medida",

    //Filtros Secundários Consulta de Pneus
    //Secondary Filters Tire Inquiry
    rfid: "RFID",
    reforms: "Reformas",
    state: "Estado",
    calibration: "Calibração",
    pressure_recommended: "Pressão Recomendada",
    brand_band: "Marca Banda",
    model_band: "Modelo Banda",
    amount_grooves: "Quantidade Sulcos",
    segment: "Segmento",
    use: "Uso",
    all_grooves: "Todos os Sulcos",
    groove1: "Sulco 1 <=",
    groove2: "Sulco 2 <=",
    groove3: "Sulco 3 <=",
    groove4: "Sulco 4 <=",

    //Tire History
    history_tire: "Histórico do Pneu",
    km_total: "Km Total do registro",
    km_banda: "Km Banda do registro",
    novo_km: "Novo Km",

    //Editar Pneu
    update_tire: "Editar Pneu",

    // Auditoria Pneu
    tire_audit: "Auditoria Edições de Pneu",

    //Cadastros
    measure_tire: "Cadastrar Medida de Pneu",
    consultar_medida_pneu: "Consulta Medida de Pneu",
    editar_medida_pneu: "Editar Medida de Pneu",

    cad_pressao_recomendada: "Cadastrar Pressão Recomendada",
    consultar_pressao_recomendada: "Consulta Pressão Recomendada",
    editar_pressao_recomendada: "Editar Pressão Recomendada",

    marca_banda: "Cadastrar Marca de Banda",
    consultar_marca_banda: "Consulta Marca de Banda",
    editar_marca_banda: "Editar Marca de Banda",

    consulta_reformador: "Consulta Reformador",
    editar_reformador: "Editar Reformador",
    cadastrar_reformador: "Cadastrar Reformador",

    consulta_modelo_pneu: "Consulta Modelo Pneu",
    editar_modelo_pneu: "Editar Modelo Pneu",
    cadastrar_modelo_pneu: "Cadastrar Modelo Pneu",

    consulta_modelo_banda: "Consulta Modelo Banda",
    editar_modelo_banda: "Editar Modelo Banda",
    cadastrar_modelo_banda: "Cadastrar Modelo Banda",

    consulta_segmento: "Consulta Segmento",
    editar_segmento: "Editar Segmento",
    cadastrar_segmento: "Cadastrar Segmento",

    consulta_tipo_uso: "Consulta Tipo Uso",
    editar_tipo_uso: "Editar Tipo Uso",
    cadastrar_tipo_uso: "Cadastrar Tipo Uso",

    consulta_usuario: "Consulta Usuário",
    editar_usuario: "Editar Usuário",
    cadastrar_usuario: "Cadastrar Usuário",
    document: "Doc",
    acesso_ti: "Acesso TI",

    consulta_perfil: "Consulta Perfil",
    editar_perfil: "Editar Perfil",
    cadastro_perfil: "Cadastrar Perfil",

    profundidade: "Profundidades(mm)",
    reformador: "Reformador",
    cnpj: "CNPJ",
    endereco: "Endereço",
    tipo_banda: "Tipo Banda",
    tipo_veiculo: "Tipo Veículo",
    tipo_pneu: "Tipo Pneu",
    tipo_uso: "Tipo Uso",
    eixo: "Eixo",
    btn_cadastrar: "Cadastrar",
    ativo: "Ativo",
    off_road: "Off-Road",
    editar_medida: "Editar Medida",
    perfil: "Perfil",
    unidade: "Unidade",

    //Lote Reformas
    novo_lote: "Novo Lote",
    status_lote: "Status Lote",
    consulta_lote: "Consulta Lote",
    lote_id: "Lote ID",
    tipo_lote: "Tipo Lote",
    editar_lote: "Editar Lote",
    compor_lote: "Compor Lote",
    data_abertura: "Data Abertura",
    data_envio: "Data Envio",
    caracteriza_reforma: "Caracterização Reformas",
    caracteriza_conserto: "Caracterização Consertos",
    posicao_conserto: "Posicão Conserto",
    filtros: "Filtros",
    sulco_max: "Sulco Max",
    receber_lote: "Receber Lote ",
    custo: "Custo",
    upload_Nf: "Nota Fiscal",
    status_pneu_lote: "Status Pneu Lote",
    status_pneu: "Status Pneu",

    //Tradução da tabela
    noRow: "Não há dados",
    search: "Procurar",
    searchPlaceholder: "Digite aqui o que procura...",
    download: "Download CSV",
    downloadTitle: "Download CSV",
    downloadDescription: "Efetue o download do arquivo em formato CSV",
    downloadSelectedRows: "Linhas selecionadas",
    downloadCurrentRows: "Linhas da página atual",
    downloadAllRows: "Todas linhas",
    display: "Exibir colunas",
    orderBy: "Ordenar por",
    drag: "Arrastar",
    paginationRows: "Linhas",
    paginationPage: "Página",

    marca_primeira_vida: "Marca 1ª Vida",
    uso_place_holder: "Selecione o uso",
    segmento_place_holder: "Selecione o segmento",
    measure_place_holder: "Selecione a medida",
    brand_place_holder: "Selecione a Marca",
    brand_band_place_holder: "Selecione a Marca Banda",
    model_band_place_holder: "Selecione o Modelo Banda",
    modelo_place_holder: "Selecione o Modelo",
    reforma_place_holder: "Selecione o Nº Reformas",
    reformador_place_holder: "Selecione o Reformador",
    status_place_holder: "Selecione o Status",
    tipo_banda_place_holder: "Selecione o Tipo de Banda",
    tipo_pneu_place_holder: "Selecione o Tipo de Pneu",
    posicao_conserto_place_holder: "Selecione a Posição do Conserto",
    multi_select_data_table_selected: "{0} Itens Selecionados",
    warning_itens_selected: "Itens Selecionados",
    estado_place_holder: "Selecione o Estado",

    //Usuarios
    usuarios_id: "ID",
    usuarios_nome: "Nome",
    usuarios_sobrenome: "Sobrenome",
    usuarios_entidade: "Entidade",
    usuarios_cpf: "CPF",
    usuarios_nascimento: "Nascimento",
    usuarios_email: "E-mail",
    usuarios_level: "Perfil",
    usuarios_senha: "Senha",
    usuarios_confirmarSenha: "Confirmar Senha",
    usuarios_cadastradoSucesso: "Usuário cadastrado com sucesso!",
    usuarios_editadoSucesso: "Usuário editado com sucesso!",
    usuarios_excluidoSucesso: "Usuário removido com sucesso!",
    usuarios_novoUsuario: "Novo Usuário",
    usuarios_listUsuarios: "Lista de Usuários",
    usuarios_excluir: "Deseja exluir o Usuário ?",
    usuarios_cadastrarUsuario: "Cadastrar Usuário",
    usuarios_editarUsuarios: "Editar Usuario",
    usuarios_erroEmailInvalido: "Email Inválido",
    usuarios_erroConfirmarSenha: "Confirme a senha corretamente",
    usuarios_telefone: "Telefone",
    usuarios_celular: "Celular",
    usuarios_dendereco: "Endereço",
    usuariso_cidade: "Cidades",
    usuarios_estado: "Estados",
    usuarios_username: "Usuário",
    usuarios_cargo: "Cargo",
    msgErroUser: "'Username já existente!'",

    //Gestão de desempenho
    gest_desempenho_performance_pneus: "Performance dos pneus por marca, segmento e tipo de Uso.",
    gest_desempenho_demo_grafic:
      "O gráfico demonstra quanto o grupo de pneus percorre (km) para desgastar 1mm da borracha.",
    gest_desempenho_contemp_grafc:
      "Este gráfico contempla apenas pneus primeira vida (0 reformas).",
    gest_desemp_contemp_reforms: "Este gráfico contempla apenas pneus reformados.",
    gest_desempenho_obs_tipo: "Obs: Tipo de Uso = Tipo de Operação.",
    gest_desempenho_performance_pneus_mod:
      "Performance dos pneus por marca, medida, segmento e tipo de Uso.",
    gest_desempenho_performance_pneus_medida:
      "Performance dos pneus por marca, modelo, segmento, tipo de Uso",
    gest_desempenho_performance_pneu_mod_banda:
      "Performace dos pneus por marca banda, modelo banda, segmento, uso",

    dash_mm_km_marca: "Km/mm Médio por Marca (Primeira Vida)",
    dash_mm_km_modelo: "Km/mm Médio por Modelo (Primeira Vida)",
    dash_mm_km_marca_banda: "Km/mm Médio por Marca Banda",
    dash_mm_km_modelo_banda: "Km/mm Médio por Modelo Banda",
    dash_mm_km_medida: "Km/mm Médio por Medida (Primeira vida)",

    //Gestão de ativos
    gest_ativos_quant_descart_12meses:
      "Quantidade de pneus descartados por mês, considerando os últimos 12 meses a partir da data atual.",
    gest_ativos_obs_tipo: "Obs: Tipo de Uso = Tipo de Operação",
    gest_ativos_pneus_chips:
      "Quantidade de pneus com chips instalados e registrados por mês no sistema, considerando os últimos 12 meses.",
    gest_ativos_pneus_verificados: "% de Pneus em Uso Verificados ",
    gest_ativos_pneus_uso_com_calib: "% de Pneus em Uso com pressão de calibragem ",
    gest_ativos_pneus_uso_pres_calib: "% de Pneus em Uso que possuem pressão de calibragem ",
    gest_ativos_pneus_uso_pres_calib_press:
      "independente da classificação da pressão e considerando ",
    gest_ativos_pneus_uso_pres_calib_operacoes: "todas as operações  que realizam medição. ",
    gest_ativos_pneus_com_verif: "% de Pneus em Uso com ",
    gest_ativos_percent: "Percentual de pneus em uso que não sofreram ",
    gest_ativos_percent_veicle: "Percentual de veículos com ",
    gest_ativos_ver_complet: "Verificação Completa ",
    gest_ativos_ver_parcial: "Verificação Parcial ",
    gest_ativos_ultimos30: "nos últimos ",
    gest_ativos_classific_recomens: "classificação de calibração conforme pressão recomendada",
    gest_ativos_quant_por_uso: "Quantidade de pneus em uso por ",
    gest_ativos_dist_pneu_marca: "Percentual de pneus monitorados por ",
    gest_ativos_porcent_marca:
      "Marcas que representarem percentual menor que 1% serão acrescentadas na categoria Outros.",
  },
};
const locale = new LocalizedStrings(locales);

export { locale };
