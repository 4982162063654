import React from "react";
import ReactDOM from "react-dom";
import { Beforeunload } from "react-beforeunload";
import { HashRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "babel-polyfill";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "font-awesome/css/font-awesome.css";
import "primereact/resources/primereact.min.css";
import "./App.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/Login/AutenticacaoAD/authConfig";
import { ADApp } from "./pages/Login/AutenticacaoAD/App"
import { locale } from "./locales";
import App from "./App"

const msalInstance = new PublicClientApplication(msalConfig);

if (locale.usaAD === 1) {
  ReactDOM.render(
    //AD
    <HashRouter>
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Beforeunload
            onBeforeunload={() => {
              localStorage.lastsession = new Date().getTime() + 1000000;
            }}/>
            <ADApp/>
        </MsalProvider>
      </React.StrictMode>
    </HashRouter>,
    document.getElementById("root")
    );  
} else {
  ReactDOM.render(
    //Savetyre
    <React.StrictMode>
      <Beforeunload
        onBeforeunload={() => {
          localStorage.lastsession = new Date().getTime() + 1000000;
        }}
      />
      <HashRouter>
        <App></App>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );  
}

  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
